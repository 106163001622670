<template>
    <div v-click-outside="hide" class="w-100 position-md-relative">
        <vs-input
            id="search-items"
            ref="brandSearchInput"
            v-model="keySearch"
            autocomplete="off"
            border
            type="text"
            name="brand-name"
            :label="label"
            placeholder="Buscar marca .."
            class="w-100 opacity-false"
            :disabled="disabledInput || !enabled"
            @input="onInput"
            @focus="emitFocus"
            @blur="onBlur"
            @click-icon="requiredSearch"
            @keyup.esc="focus = -1"
            @keyup.enter="onEnter"
            @keyup.down="incrementFocus"
            @keyup.up="decrementFocus"
        >
            <template #icon> 🔎 </template>
        </vs-input>
        <div
            v-if="show"
            class="position-absolute bg-base z-40 px-4 mt-1 top-4 inset-x-0 md:left-auto origin-top-right md:origin-top"
        >
            <div
                class="w-100 rounded pb-2 border-b border-right border-left border-secondary"
            >
                <div class="overflow-auto h-max-96 w-40">
                    <div
                        v-for="(item, i) in results"
                        :key="i"
                        class="w-100 p-0"
                        :class="{ 'form-control': focus === i }"
                    >
                        <button
                            type="button"
                            class="w-100 btn text-success rounded-0 mb-2"
                            @click="clickButton(i)"
                        >
                            {{ item.name }}
                        </button>
                    </div>
                    <div
                        v-if="isLoading"
                        class="w-100 d-flex align-items-center justify-content-center"
                    >
                        Cargando...
                    </div>

                    <div
                        v-if="!results.length && !isLoading"
                        class="py-4 px-2 d-flex justify-content-center"
                    >
                        <span class="font-weight-bold text-md">
                            Nuestro sistema no encuentra resultados 😢
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "BrandSearch",
    directives: {
        ClickOutside
    },
    model: {
        prop: "itemCurrent",
        event: "input"
    },
    props: {
        defaultFilters: {
            default: () => ({
                active: true
            }),
            type: Object,
            required: false
        },
        itemCurrent: {
            default: () => ({ name: "" }),
            type: Object,
            required: false
        },
        label: {
            default: () => "",
            type: String,
            required: false
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        }
    },
    emits: ["blur", "focus", "input"],
    data: () => ({
        keySearch: "",
        isLoading: false,
        show: false,
        disabledInput: false,
        focus: -1,
        results: [],
        selectedKey: ""
    }),
    computed: {
        ...mapGetters("control", ["backgroundColor"])
    },
    watch: {
        itemCurrent(value) {
            this.setInput(value);
        }
    },
    mounted() {
        this.setInput(this.itemCurrent);
    },
    methods: {
        ...mapActions("brands", ["listAllBrands"]),
        requiredSearch() {
            this.debounce(this.advanceSearch, 0, this.keySearch, true);
        },
        async advanceSearch(keyword, required = false) {
            if (!keyword) return;
            this.isLoading = true;
            try {
                this.results = await this.listAllBrands({
                    ...this.defaultFilters,
                    keyword,
                    required
                });

                this.focus = -1;
            } catch (error) {
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        async onInput() {
            this.show = !!this.keySearch;
            await this.advanceSearch(this.keySearch, false);
        },
        onBlur() {
            this.$emit("blur");
        },
        emitFocus() {
            if (this.selectedKey === this.keySearch) {
                this.keySearch = "";
            }
            this.$emit("focus");
        },
        emitEnter() {
            const item = this.results[this.focus];
            this.$emit("input", item);
            this.selectedKey = `${item.name}`;
            this.focus = -1;
            this.show = false;
            this.disabledInput = true;
            setTimeout(() => {
                this.disabledInput = false;
            }, 300);
        },
        hide() {
            this.keySearch = `${this.selectedKey}`;
            this.show = false;
            this.focus = -1;
        },
        onEnter() {
            if (this.focus > -1) {
                this.emitEnter();
            } else {
                this.requiredSearch();
            }
        },
        clickButton(index) {
            this.focus = index;
            this.emitEnter();
        },
        incrementFocus() {
            if (this.focus === -1) {
                this.focus = 0;
            } else if (this.focus < this.results.length - 1) {
                this.focus = this.focus + 1;
            }
        },
        decrementFocus() {
            if (this.focus === -1) {
                this.focus = 0;
            } else if (this.focus > 0) {
                this.focus = this.focus - 1;
            }
        },
        setInput(value) {
            this.keySearch = `${value.name}`;
            this.selectedKey = `${value.name}`;
        }
    }
};
</script>
